import React, { useState, useEffect, useRef } from 'react';
import { SearchField } from '@jetshop/ui/Search';
import { useIntl } from '@jetshop/intl';
import Styled from './Style';
import AutocompleteQuery from '../../../../gql/queries/AutocompleteQuery.gql';

const DesktopSearch = () => {
  const [searchOpen, setSearchOpen] = useState(true);
  const t = useIntl();
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (searchOpen && wrapperRef.current) {
      // Give the component time to render
      setTimeout(() => {
        const inputElement = wrapperRef.current.querySelector(
          'input[type="search"]'
        );
        if (inputElement) {
          // Create a unique ID for the label
          const labelId = 'search-label';

          // Check if label already exists
          if (!document.getElementById(labelId)) {
            // Create a visually hidden label
            const label = document.createElement('label');
            label.id = labelId;
            label.innerHTML = t('Search');
            label.style.position = 'absolute';
            label.style.width = '1px';
            label.style.height = '1px';
            label.style.padding = '0';
            label.style.margin = '-1px';
            label.style.overflow = 'hidden';
            label.style.clip = 'rect(0, 0, 0, 0)';
            label.style.whiteSpace = 'nowrap';
            label.style.border = '0';

            // Insert the label before the wrapper
            wrapperRef.current.prepend(label);

            // Associate the label with the input
            inputElement.setAttribute('aria-labelledby', labelId);
          }
        }
      }, 100);
    }
  }, [searchOpen, t]);

  return (
    <Styled.Wrapper ref={wrapperRef}>
      {searchOpen && (
        <Styled.PosedSearchBarContainer key={'searchBarPosed'}>
          <Styled.InnerSearchBar>
            <SearchField
              autocompleteQuery={AutocompleteQuery}
              onCancel={() => setSearchOpen(true)}
              className={Styled.searchFieldStyles}
              placeholder={`${t('Search')}...`}
            />
          </Styled.InnerSearchBar>
        </Styled.PosedSearchBarContainer>
      )}
    </Styled.Wrapper>
  );
};

export default DesktopSearch;
